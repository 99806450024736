import React from "react";
import backgroundImage from "images/banners/bg-about-mission.jpg";
import styled from "styled-components";
import {
  AboutUsNav,
  BannerTitle,
  Layout,
  PageContainer,
  SEO,
} from "components";
import { Col, Row } from "reactstrap";
import { VideoPlayer } from "components";
import { device } from "utils";

const OurCulturePage = ({ location }) => {
  const title = "About Us";
  const description = "ECL was founded by scientists, for scientists";
  const video = "https://player.vimeo.com/video/249450681";
  const videoTitle = "The ECL:<br />Built for scientists by scientists";

  return (
    <>
      <Layout clearNav location={location}>
        <SEO
          image={backgroundImage}
          title={`${title} | Our Culture`}
          description={description}
          uri={location.pathname}
        />
        <BannerTitle image={backgroundImage}>{title}</BannerTitle>
        <PageContainer>
          <AboutUsNav section={location && location.pathname} />
          <Row>
            <ColContent xs={12} md={12} lg={12} xl={6}>
              <Title>
                At Emerald Cloud Lab our mission is to empower scientists to
                transcend the laboratory.
              </Title>
              <VideoWrapperMobile>
                <VideoPlayer video={video} videoTitle={videoTitle} />
              </VideoWrapperMobile>
              <Introduction>
                <p>
                  ECL was founded by scientists, for scientists. Our vision is
                  to build a system that sweeps aside the daily grind scientists
                  face in the laboratory and allows the day-to-day work to
                  center on orchestrating science. There is transformative
                  potential in a world where scientific ideas have a more direct
                  route to realization and where progress in science and
                  medicine is driven more by the strength of our ideas than our
                  labor in the lab. Succeeding in this mission has the chance to
                  provide unprecedented leverage and autonomy to scientists
                  worldwide and in doing so to accelerate the rate of progress
                  in pharmaceutical research, materials science, medical
                  diagnostics, and agriculture.
                </p>
                <p>
                  Turning this vision into reality is what has brought us all
                  together at Emerald. Our mission drives us to do the kind of
                  work we are proud to tell our friends and families about, and
                  the grand ambition of the endeavor presents each of us with
                  the kinds of challenges that can inspire the most creative
                  work of our careers.
                </p>
              </Introduction>
            </ColContent>
            <ColVideoDesktop
              xs={12}
              md={12}
              lg={12}
              xl={{ size: 5, offset: 1 }}
            >
              <VideoWrapperDesktop>
                <VideoPlayer video={video} videoTitle={videoTitle} />
              </VideoWrapperDesktop>
            </ColVideoDesktop>
          </Row>
        </PageContainer>
      </Layout>
    </>
  );
};

export default OurCulturePage;

const ColContent = styled(Col)`
  margin-bottom: 15rem;
`;

const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 2rem;
`;

const VideoWrapperMobile = styled(Col)`
  display: block;
  margin-bottom: 2rem;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  position: relative;

  @media ${device.lg} {
    display: none;
  }
`;

const ColVideoDesktop = styled(Col)`
  display: none;

  @media ${device.lg} {
    display: block;
  }
`;

const VideoWrapperDesktop = styled.div`
  display: none;

  @media ${device.lg} {
    display: block;
    margin-bottom: 2rem;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    position: relative;
  }
`;

const Introduction = styled.div`
  color: ${({ theme }) => theme.gray};
`;
